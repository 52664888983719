<template>
  <div>
    <div id="header">
      户号绑定
      <a href="javascript:history.back(-1)"></a>
    </div>
    <div class="sntsoft">
      <img class="bound_img" src="../../assets/bangding/bg.png"/>
      <div class="all_box">
        <form id="boundes" action="" method="post" name="formbound">
          <div class="all_c">
            <div class="am-g" style="padding-bottom: 15px">
              <div class="am-u-sm-11 am-u-sm-centered">
                <span class="bound_icon">新增户号</span>
              </div>
            </div>

            <div class="am-g" style="padding-bottom: 20px">
              <div class="am-u-sm-11 am-u-sm-centered">
                <div class="am-input-group am-input-group-secondary">
                  <input
                      @input="filterNumber('phoneNum')"
                      @click="clickInput"
                      type="text"
                      name="number"
                      id="txtnumber"
                      class="am-form-field am-radius"
                      placeholder="请输入手机号"
                      v-model="phoneNum"
                  />
                </div>
              </div>
            </div>

            <div class="am-g" style="padding-bottom: 15px">
              <div class="am-u-sm-11 am-u-sm-centered">
                <div
                    style="margin-bottom: 10px"
                    class="am-input-group am-input-group-secondary"
                >
                  <input
                      @input="filterNumber('userNo')"
                      @click="clickInput"
                      type="text"
                      name="vnumber"
                      id="txtnumber"
                      class="am-form-field am-radius"
                      placeholder="请输入户号/表编号"
                      v-model="userNo"
                  />
                </div>
              </div>
            </div>

            <div class="am-g boundtext" style="margin-top: 15px">
              <div class="am-u-sm-11 am-u-sm-centered">
                <div class="bound_check">
                  <div>如何查询户号？</div>
                  <div>
                    <div>1、在历史票据中找到户号</div>
                    <div>2、通过咨询线下缴费点查询您的户号</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="am-g">
            <div class="bound_pd" style="padding: 0">
              <div class="am-u-sm-11 am-u-sm-centered" style="margin-top: 20px">
                <input
                    type="button"
                    id="btnSubmit"
                    value="提交信息"
                    class="new_btn"
                    @click="submit"
                />
              </div>
              <dl class="ts" style="margin: 0">
                <dt>温馨提示</dt>
                <dd>
                  为保证用户信息安全，一个网上账号绑定用水磁卡号的上限为10户。
                  同一用水磁卡号最多可绑定在6个网上账号（跨平台）。
                </dd>
              </dl>
            </div>
          </div>
        </form>
      </div>

      <!--底部-->
      <link rel="stylesheet" href="tpl/static/publicwap/css/style.css"/>
      <div class="footer" style="height: 50px; width: 100%"></div>
      <div
          data-role="footer"
          data-position="fixed"
          data-fullscreen="true"
          data-visible-on-page-show="false"
          style="text-align: center"
      >
        <div class="footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ajax from '@/api'
import {userIdCache} from '@/assets/js/cache'

export default {
  name: 'UserBind',
  components: {},
  data() {
    return {
      userNo: '',
      phoneNum: ''
    }
  },

  methods: {
    // 过滤数字
    filterNumber(data) {
      this[data] = this[data].replace(/\D/g, '')
    },
    // 点击聚焦
    clickInput(e) {
      e.target.focus()
    },
    submit() {
      const phoneNum = this.phoneNum.replace(/\s/g, '')
      const phoneReg = /^1\d{10}$/
      const userNo = this.userNo.replace(/\s/g, '')
      var errMsg = ''
      if (phoneReg.length === 0 || userNo.length === 0) {
        errMsg = '请填写完整'
      } else if (!phoneReg.test(phoneNum)) {
        errMsg = '手机号格式错误'
      }
      if (errMsg) {
        this.$toast.fail(errMsg)
        return
      }
      ajax.bindAccount({
        userNo: userNo,
        phoneNum: phoneNum,
        userId: userIdCache.getCache()
      })
          .then(res => {
            if (res.code != 200) {
              this.$toast.fail(res.msg)
            } else {
              this.$toast.success({
                message: '绑定成功',
                duration: 500,
                onClose: () => {
                  this.$router.back()
                }
              })
            }
          })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>

<style>
</style>
